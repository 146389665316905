
// import  List  from './List';
// import AppCard from './AppCard';
import MainRoutes from './MainRoutes';

function App() {
  return (
    <div className="App">
      <MainRoutes />
    </div>
  );
}

export default App;
