import React, { useState, useEffect, } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import request from "../functions/request"
import { toast } from 'react-toastify';

export default function Delete({ categoryId, openDeleteModal, setOpenDeleteModal, categories, setCategories }) {

  async function deleteCategory() {
    await request("/admin/category/delete", "POST", { id: categoryId }).then(res => {
      if (res.success) {
      }
    })
    toast("Category is deleted")

  }

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setOpenDeleteModal(false);
    deleteCategory()
    setCategories(categories.filter(c => c.id != categoryId))


  };

  return (
    <div>
      <Dialog
        open={openDeleteModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Category "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
