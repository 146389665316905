import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import request from "../functions/request";
import SelectCategories from "../SelectCategories";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";

// import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

export default function DetailsSideBar({
  anchor,
  selectedRow,
  openDrawer,
  setOpenDrawer,
  appUpdated,
  setAppUpdated,
}) {
  const [state, setState] = React.useState({
    right: openDrawer,
  });
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [defaultCategories, setDefaultCategories] = useState([]);

  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [name, setName] = useState(selectedRow.name);
  const [title, setTitle] = useState(selectedRow.title);
  const [order, setOrder] = useState(selectedRow.order);
  const [description, setDescription] = useState();
  const [showInMainScreen, setShowInMainScreen] = useState(false);
  const [showInIntroScreen, setShowInIntroScreen] = useState(false);

  const toggleDrawer = (anchor, openDrawer) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: openDrawer });
    setOpenDrawer(false);
  };
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getDetails();
  }, [categories]);

  function getCategories() {
    request("/admin/category", "POST", { searchValue: "" }).then((res) => {
      if (res.success) {
        setCategories(res.data);
      }
    });
  }

  function filterDefaultCategoriesNames(ids) {
    let names = [];
    let result = categories.filter((c) => ids.includes(c.id));
    result.map((item) => {
      names.push(item.name);
    });
    setDefaultCategories(names);
    setSelectedCategoryIds(ids);
  }

  function getDetails() {
    request("/admin/app/details", "POST", {
      storeId: selectedRow.storeId,
      platform: selectedRow.platform,
    }).then((res) => {
      if (res.success) {
        setDescription(res.data[0].description);
        // setDetails(res.data)
        filterDefaultCategoriesNames(res.data[0].categories);
        setDetails(res.data[0]);
        setShowInMainScreen(res.data[0].showInMainScreen);
        setShowInIntroScreen(res.data[0].showInIntroScreen);

        setLoading(false);
      }
    });
  }

  // function enlargeImg() {
  //   let img = document.getElementById("img1");
  //   // Set image size to 1.5 times original
  //   img.style.transform = "scale(1.5)";
  //   // Animation effect
  //   img.style.transition = "transform 0.25s ease";
  // }

  const handleUpdate = async () => {
    if (name.trim() == "") {
      return toast.warn("Name can not be empty");
    }
    if (title.trim() == "") {
      return toast.warn("Title can not be empty");
    }
    if (description.trim() == "") {
      return toast.warn("Description can not be empty");
    }
    console.log(showInIntroScreen);
    await request("/admin/app/update", "POST", {
      id: selectedRow.id,
      storeId: selectedRow.storeId,
      platform: selectedRow.platform,
      name,
      showInMainScreen: !!showInMainScreen,
      showInIntroScreen: !!showInIntroScreen,
      order: !!order ? +order : null,
      categories: selectedCategoryIds,
      storeTitle: title,
      description,
    }).then((res) => {
      if (res.success) {
        setAppUpdated((prev) => !prev);
        toast("App is updated");
      } else {
        toast.error(res.message);
      }
    });
    await setOpenDrawer(false);
  };
  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  const handleChangeOrder = (e) => {
    setOrder(e.target.value);
  };
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleUpdateFromStore = async () => {
    await request("/admin/app/update/fromStore", "POST", {
      appId: selectedRow.id,
      platform: selectedRow.platform,
      url: selectedRow.url,
    }).then((res) => {
      // if (res.success) {
      setAppUpdated(!appUpdated);
      // }
    });
    await setOpenDrawer(false);
    toast("App is updated from store");
  };

  const list = (anchor) => {
    return (
      <Box
        style={{
          paddingBottom: 80,
          height: "100vh",
          overflow: "auto",
          width: anchor === "top" || anchor === "bottom" ? "auto" : 893,
        }}
        role="presentation"
      >
        <h2 style={{ display: "flex", justifyContent: "space-around" }}>
          App Details
        </h2>

        <TextField
          style={{ marginTop: "20px", marginLeft: "50px", width: "388px" }}
          id="outlined-id-input"
          label="Id"
          type="text"
          defaultValue={selectedRow.id}
          disabled={true}
          variant="filled"
        />

        <TextField
          style={{ marginTop: "20px", marginLeft: "20px", width: "388px" }}
          id="outlined-id-input"
          label="Name"
          type="text"
          defaultValue={selectedRow.name}
          variant="filled"
          onChange={(e) => handleChangeName(e)}
        />

        <div
          style={{
            display: "flex",
            marginLeft: "50px",
            alignContent: "center",
            gap: 16,
            marginTop: 24,
            alignItems: "center",
          }}
        >
          <TextField
            style={{ width: "300px" }}
            id="outlined-order-input"
            label="Order"
            type="text"
            defaultValue={selectedRow.order}
            onChange={(e) => handleChangeOrder(e)}
            variant="filled"
          />
          {!!defaultCategories.length && (
            <SelectCategories
              selectedCategoryIds={selectedCategoryIds}
              setSelectedCategoryIds={setSelectedCategoryIds}
              defaultCategories={defaultCategories}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "50px",
            alignContent: "center",
            gap: 16,
            marginTop: 24,
            alignItems: "center",
          }}
        >
          <FormGroup sx={{ mx: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showInMainScreen}
                  onChange={(e) => setShowInMainScreen(!showInMainScreen)}
                />
              }
              label="Show in main screen"
            />
          </FormGroup>
          <FormGroup sx={{ mx: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showInIntroScreen}
                  onChange={(e) => setShowInIntroScreen(!showInIntroScreen)}
                />
              }
              label="Show in intro screen"
            />
          </FormGroup>
        </div>
        <div>
          <Divider />
        </div>

        <TextField
          style={{ marginTop: "20px", marginLeft: "50px", width: "388px" }}
          id="outlined-storeTitle-input"
          label="Store Title"
          type="text"
          defaultValue={details.title}
          variant="filled"
          onChange={(e) => handleTitle(e)}
        />

        <TextField
          style={{ marginTop: "20px", marginLeft: "15px", width: "388px" }}
          id="outlined-url-input"
          label="Url"
          type="text"
          disabled={true}
          defaultValue={details.url}
          variant="filled"
        />

        <div>
          <TextField
            style={{ marginTop: "20px", marginLeft: "50px", width: "792px" }}
            id="outlined-description-input"
            label="Description"
            type="text"
            multiline
            maxRows={10}
            defaultValue={details.description}
            // disabled={true}
            variant="filled"
            onChange={(e) => handleDescription(e)}
          />
        </div>
        <div style={{ marginLeft: "50px", marginRight: "50px" }}>
          <h2>Information</h2>

          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ display: "grid", width: "400px" }}>
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Genres
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {details.genres}
              </label>
            </div>

            <div
              style={{ display: "grid", width: "400px", marginLeft: "20px" }}
            >
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Content Rating
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {details.contentRating}
              </label>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ display: "grid", width: "400px" }}>
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Size
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {details.size}
              </label>
            </div>

            <div
              style={{ display: "grid", width: "400px", marginLeft: "20px" }}
            >
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Released
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {details.released}
              </label>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ display: "grid", width: "400px" }}>
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Updated
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {details.updated}
              </label>
            </div>

            <div
              style={{ display: "grid", width: "400px", marginLeft: "20px" }}
            >
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Price
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {details.price}
              </label>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ display: "grid", width: "400px" }}>
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Version
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {details.version}
              </label>
            </div>

            <div
              style={{ display: "grid", width: "400px", marginLeft: "20px" }}
            >
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Developer
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {details.developer}
              </label>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ display: "grid", width: "400px" }}>
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Free
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {String(details.free)}
              </label>
            </div>

            <div
              style={{ display: "grid", width: "400px", marginLeft: "20px" }}
            >
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Score
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {details.score}
              </label>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ display: "grid", width: "400px" }}>
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Release Notes
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {details.releasenotes}
              </label>
            </div>

            <div
              style={{ display: "grid", width: "400px", marginLeft: "20px" }}
            >
              <label
                style={{ fontWeight: "bold", color: "rgba(10, 10, 10, 0.75)" }}
              >
                Developer Website
              </label>
              <label
                style={{
                  fontWeight: "lighter",
                  color: "gray",
                  marginLeft: "20px",
                }}
              >
                {details.developerWebsite}
              </label>
            </div>
          </div>
        </div>
        <div>
          <div style={{ marginLeft: "50px" }}>
            <h2>Screenshots </h2>
          </div>
          <div style={{ marginLeft: "40px" }}>
            {details.screenshots.map((item, ind) => {
              return (
                <img
                  id={"img" + ind}
                  key={ind}
                  style={{
                    height: "100px",
                    marginTop: "20px",
                    marginLeft: "12px",
                  }}
                  src={item}
                ></img>
              );
            })}
          </div>
        </div>
        <div
          style={{
            padding: 16,
            background: "white",
            position: "absolute",
            width: "calc(100% - 48px)",
            bottom: 0,
          }}
        >
          <Button
            style={{ float: "right" }}
            onClick={handleUpdate}
            variant="contained"
          >
            Update
          </Button>
          <Button onClick={handleUpdateFromStore} variant="outlined">
            Update from Store
          </Button>
        </div>
      </Box>
    );
  };

  return (
    <>
      <div>
        <React.Fragment key={"right"}>
          <SwipeableDrawer
            anchor={"right"}
            open={state["right"]}
            style={{ position: "relative" }}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
          >
            {loading ? null : list("right")}
          </SwipeableDrawer>
        </React.Fragment>
      </div>
    </>
  );
}
