import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import SignIn from "./SignIn";
import AppBar from "./AppBar";

function MainRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {!localStorage.getItem("token") ? (
            <>
              <Route path="/signIn" element={<SignIn />} />
              <Route path="*" element={<Navigate to="/signIn" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<AppBar />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default MainRoutes;
