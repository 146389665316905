import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SelectCategories from "../SelectCategories";
import request from "../functions/request";
import { toast } from "react-toastify";

export default function FormDialog({
  addAppModal = false,
  setAddAppModal,
  appAdded,
  setAppAdded,
}) {
  const [selectedCategoryIds, setSelectedCategoryIds] = React.useState([]);
  const [appName, setAppName] = React.useState();
  const [googlePlayURL, setGooglePlayURL] = React.useState();
  const [appStoreURL, setAppStoreURL] = React.useState();
  const [order, setOrder] = React.useState("");

  const handleClose = () => {
    setAppName();
    setGooglePlayURL();
    setAppStoreURL();
    setSelectedCategoryIds([]);
    setAddAppModal(false);
    setAppAdded(true);
  };

  const handleAdd = async () => {
    let isAppAdded = false;
    let toastMessage = "App is not added";
    await request("/admin/app/addNew", "POST", {
      appId: 0,
      appName,
      googleLink: googlePlayURL,
      iosLink: appStoreURL,
      categories: selectedCategoryIds,
      order: +order,
    }).then(async (res) => {
      if (res.success && (res.sendingRes.googleAdd || res.sendingRes.iosAdd)) {
        isAppAdded = true;
        toastMessage = "App is added";
        setAppAdded(isAppAdded);
      } else {
        toastMessage = res.responseMessage || "App is not added";
      }
      toast(toastMessage);
    });
    setAppName("");
    setGooglePlayURL("");
    setAppStoreURL("");
    setSelectedCategoryIds([]);
    setAddAppModal(false);
  };

  const handleName = (e) => {
    setAppName(e.target.value);
  };
  const handleGooglePlayURL = (e) => {
    setGooglePlayURL(e.target.value);
  };
  const handleAppStoreURL = (e) => {
    setAppStoreURL(e.target.value);
  };
  const handleChangeOrder = (e) => {
    setOrder(e.target.value);
  };
  return (
    <div>
      <Dialog open={addAppModal} onClose={handleClose}>
        <DialogTitle>Add New App</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="App Name"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            value={appName}
            onChange={(e) => handleName(e)}
          />
          <TextField
            value={googlePlayURL}
            margin="dense"
            id="googlePlayURL"
            label="Google Play URL"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            onChange={(e) => handleGooglePlayURL(e)}
          />
          <TextField
            value={appStoreURL}
            margin="dense"
            id="appStoreURL"
            label="App Store URL"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            onChange={(e) => handleAppStoreURL(e)}
          />
          <TextField
            margin="dense"
            defaultValue={order}
            id="order"
            label="Order"
            type="number"
            fullWidth
            variant="standard"
            autoComplete="off"
            onChange={(e) => handleChangeOrder(e)}
          />
          <SelectCategories setSelectedCategoryIds={setSelectedCategoryIds} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAdd}
            disabled={
              !(
                order &&
                appName &&
                selectedCategoryIds.length &&
                (googlePlayURL || appStoreURL)
              )
            }
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
