import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SelectApp from "../SelectApp";
import request from "../functions/request";
import { toast } from "react-toastify";

export default function FormDialog({
  addToExistingAppModal = false,
  setAddToExistingAppModal,
  appAdded,
  setAppAdded,
}) {
  const [selectedCategoryIds, setSelectedCategoryIds] = React.useState([]);
  const [singleApps, setSingleApps] = React.useState([]);
  const [newPlatform, setNewPlatform] = React.useState();
  const [selectedApp, setSelectedApp] = React.useState();
  const [url, setUrl] = React.useState(null);

  const handleClickOpen = () => {
    setAddToExistingAppModal(true);
  };

  const handleClose = () => {
    setAddToExistingAppModal(false);
  };

  const handleUrl = (e) => {
    setUrl(e.target.value);
  };

  const handleAdd = async () => {
    await request("/admin/app/update/fromStore", "POST", {
      appId: selectedApp.id,
      platform: selectedApp.platform == "ios" ? "android" : "ios",
      url,
    }).then((res) => {
      // if (res.success) {
      // }
    });
    setAppAdded(!appAdded);
    setAddToExistingAppModal(false);
    toast("App is added");
  };
  useEffect(() => {
    getSingleApps();
  }, [appAdded]);

  function getSingleApps() {
    request("/admin/app/single", "POST").then((res) => {
      if (res.success) {
        setSingleApps(res.data);
      }
    });
  }

  return (
    <div>
      <Dialog open={addToExistingAppModal} onClose={handleClose}>
        <DialogTitle>Add To Existing App</DialogTitle>
        <DialogContent>
          <SelectApp singleApps={singleApps} setSelectedApp={setSelectedApp} />

          <TextField
            margin="dense"
            id="url"
            label="URL"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            onChange={(e) => handleUrl(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
