import * as React from "react";
import { useInput } from "@mui/base";
import { styled } from "@mui/system";
import "./search.css";
import TextField from "@mui/material/TextField";
import { useState } from "react";

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  const { getRootProps, getInputProps } = useInput(props, ref);

  return (
    <TextField
      {...props}
      {...getInputProps()}
      id="filled-basic"
      variant="filled"
    />
  );
});

export default function UseInput({ searchValue, setSearchValue }) {
  const [label, setLabel] = useState("Search...");

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <CustomInput
      style={{ marginTop: "8px", minWidth: 250 }}
      aria-label="Demo input"
      label={label}
      onBlur={() => setLabel("Search...")}
      onFocus={() => setLabel("Search")}
      value={searchValue}
      onChange={(e) => handleChange(e)}
    />
  );
}
