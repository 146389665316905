import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import request from "../functions/request";
import { toast } from "react-toastify";

export default function AddOrEditCategory({
  selectedCategory,
  open = false,
  onClose,
  added,
  setAdded,
}) {
  const [name, setName] = React.useState(
    selectedCategory ? selectedCategory.name : ""
  );
  const [nameEn, setNameEn] = React.useState(
    selectedCategory ? selectedCategory.nameEn : ""
  );
  const [nameRu, setNameRu] = React.useState(
    selectedCategory ? selectedCategory.nameRu : ""
  );
  const [order, setOrder] = React.useState(
    selectedCategory ? selectedCategory.order : 0
  );

  function addCategory() {
    request("/admin/category/add", "POST", {
      name,
      nameEn,
      nameRu,
      order: +order,
    }).then((res) => {
      if (res.success) {
        setAdded(!added);
        toast("Category is added");
      }
    });
  }

  function editCategory() {
    request("/admin/category/edit", "POST", {
      id: selectedCategory.id,
      name,
      nameEn,
      nameRu,
      order: +order,
    }).then((res) => {
      if (res.success) {
        setAdded((prev) => !prev);
        toast("Category is updated");
      }
    });
  }

  function handleSubmit() {
    if (selectedCategory) {
      editCategory();
    } else {
      addCategory();
    }
    setAdded((prev) => !prev);
    onClose(false);
  }

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };

  const handleChangeNameRu = (e) => {
    setNameRu(e.target.value);
  };

  const handleChangeOrder = (e) => {
    setOrder(e.target.value);
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {selectedCategory ? "Edit" : "Add New"} Category
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            defaultValue={name}
            id="nameEn"
            label="Կատեգորիայի անուն"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            onChange={(e) => handleChangeName(e)}
          />
          <TextField
            margin="dense"
            defaultValue={nameEn}
            id="name"
            label="Category Name"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            onChange={(e) => handleChangeNameEn(e)}
          />
          <TextField
            margin="dense"
            defaultValue={nameRu}
            id="nameRu"
            label="Имя Категории"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            onChange={(e) => handleChangeNameRu(e)}
          />
          <TextField
            margin="dense"
            defaultValue={order}
            id="order"
            label="Order"
            type="number"
            fullWidth
            variant="standard"
            autoComplete="off"
            onChange={(e) => handleChangeOrder(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>
            {selectedCategory ? "Edit" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
