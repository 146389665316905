import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AppsTable from "../AppsTable";
import CategoriesTable from "../CategoriesTable";
import Search from "../Search";
import SelectCategories from "../SelectCategories";
import { useNavigate } from "react-router";
import SelectPlatform from "../SelectPlatform";
import AddApp from "../AddApp";
import AddOrEditCategory from "../AddOrEditCategory";
import AddToExistingApp from "../AddToExistingApp";
import { ToastContainer } from "react-toastify";
import "./AppBar.css";
import { Banners } from "../pages";
import { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openAppsTable, setOpenAppsTable] = React.useState(true);
  const [openCategoriesTable, setOpenCategoriesTable] = React.useState(false);
  const [openBanners, setOpenBanners] = React.useState(false);
  const [addAppModal, setAddAppModal] = React.useState(false);
  const [addToExistingAppModal, setAddToExistingAppModal] =
    React.useState(false);
  const [addOrEditCategoryModalOpen, setAddOrEditCategoryModalOpen] =
    React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedCategoryIds, setSelectedCategoryIds] = React.useState([]);
  const [selectedPlatform, setSelectedPlatform] = React.useState([]);
  const [added, setAdded] = React.useState(false);
  const [appAdded, setAppAdded] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = useState();

  let navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/signIn");
    window.location.reload();
  };

  const handleApps = () => {
    setOpenAppsTable(true);
    setOpenCategoriesTable(false);
    setOpenBanners(false);
    setSearchValue("");
  };
  const handleCategories = () => {
    setOpenAppsTable(false);
    setOpenCategoriesTable(true);
    setOpenBanners(false);
    setSearchValue("");
  };

  const handleBanners = () => {
    setOpenAppsTable(false);
    setOpenCategoriesTable(false);
    setOpenBanners(true);
    setSearchValue("");
  };

  const handleAddApp = () => {
    setAddAppModal(true);
  };

  const handleAddToExistingApp = () => {
    setAddToExistingAppModal(true);
  };

  const handleAddCategory = () => {
    setAddOrEditCategoryModalOpen(true);
  };

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="100%">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            ></Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem>
                  <IconButton onClick={() => window.location.reload()}>
                    <HomeIcon sx={{ ml: -1.5, mr: 0.5 }} ml={-10} />{" "}
                    <Typography textAlign="center">Home</Typography>
                  </IconButton>
                </MenuItem>
                <MenuItem
                  onClick={handleApps}
                  style={{
                    background: openAppsTable ? "cadetblue" : undefined,
                  }}
                >
                  <Typography textAlign="center">Apps</Typography>
                </MenuItem>
                <MenuItem
                  style={{
                    background: openCategoriesTable ? "cadetblue" : undefined,
                  }}
                  onClick={handleCategories}
                >
                  <Typography textAlign="center">Categories</Typography>
                </MenuItem>
                <MenuItem
                  style={{
                    background: openBanners ? "cadetblue" : undefined,
                  }}
                  onClick={handleBanners}
                >
                  <Typography textAlign="center">Banners</Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <IconButton onClick={() => window.location.reload()}>
                <HomeIcon htmlColor="white" />
              </IconButton>
              <Button
                style={{ background: openAppsTable ? "cadetblue" : undefined }}
                onClick={handleApps}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Apps
              </Button>
              <Button
                style={{
                  background: openCategoriesTable ? "cadetblue" : undefined,
                }}
                onClick={handleCategories}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Categories
              </Button>
              <Button
                style={{ background: openBanners ? "cadetblue" : undefined }}
                onClick={handleBanners}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Banners
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key={"Logout"} onClick={handleLogout}>
                  <Typography textAlign="center">{"Logout"}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {!openBanners && (
        <div className="filterDiv">
          <div style={{ marginLeft: "20px", marginTop: 24, display: "flex" }}>
            {openAppsTable ? (
              <SelectCategories
                setSelectedCategoryIds={setSelectedCategoryIds}
              />
            ) : null}
            {openAppsTable ? (
              <SelectPlatform setSelectedPlatform={setSelectedPlatform} />
            ) : null}
            <Search searchValue={searchValue} setSearchValue={setSearchValue} />
          </div>
          <div className="addButtons">
            {openAppsTable ? (
              <div>
                <Button
                  style={{ marginRight: "20px" }}
                  onClick={handleAddApp}
                  variant="contained"
                >
                  Add New App
                </Button>
                <Button
                  style={{ marginRight: "20px" }}
                  onClick={handleAddToExistingApp}
                  variant="contained"
                >
                  Add to existing App
                </Button>
              </div>
            ) : (
              <Button
                style={{ marginRight: "20px" }}
                onClick={handleAddCategory}
                variant="contained"
              >
                Add Category
              </Button>
            )}
          </div>
        </div>
      )}

      <AppsTable
        open={openAppsTable}
        setOpen={setOpenAppsTable}
        searchValue={searchValue}
        selectedCategoryIds={selectedCategoryIds}
        selectedPlatform={selectedPlatform}
        appAdded={appAdded}
        setAppAdded={setAppAdded}
      />
      <CategoriesTable
        onEdit={(c) => {
          setSelectedCategory(c);
          setAddOrEditCategoryModalOpen(true);
        }}
        open={openCategoriesTable}
        setOpenCategoriesTable={setOpenCategoriesTable}
        searchValue={searchValue}
        added={added}
        setAdded={setAdded}
      />
      {openBanners && <Banners />}
      <AddApp
        addAppModal={addAppModal}
        setAddAppModal={setAddAppModal}
        appAdded={appAdded}
        setAppAdded={setAppAdded}
      />
      <AddToExistingApp
        addToExistingAppModal={addToExistingAppModal}
        setAddToExistingAppModal={setAddToExistingAppModal}
        appAdded={appAdded}
        setAppAdded={setAppAdded}
      />

      {addOrEditCategoryModalOpen && (
        <AddOrEditCategory
          selectedCategory={selectedCategory}
          open={addOrEditCategoryModalOpen}
          onClose={() => {
            setAddOrEditCategoryModalOpen(false);
            setSelectedCategory(null);
          }}
          added={added}
          setAdded={setAdded}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default ResponsiveAppBar;
