import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import TableRow from "@mui/material/TableRow";
import DetailsSideBar from "../DetailsSideBar";
import request from "../functions/request";
import DeleteApp from "../DeleteApp";
import { Checkbox } from "@mui/material";

const columns = [
  { id: "order", label: "Order", minWidth: 100 },
  { id: "name", label: "Name", minWidth: 100 },
  {
    id: "icon",
    label: "Icon",
    minWidth: 50,
    align: "right",
  },
  {
    id: "title",
    label: "Title",
    minWidth: 170,
    align: "right",
  },
  {
    id: "categories",
    label: "Categories",
    minWidth: 140,
    align: "right",
  },
  {
    id: "showInIntroScreen",
    label: "Show in Intro screen",
    minWidth: 140,
    align: "right",
  },
  {
    id: "showInMainScreen",
    label: "Show in Main screen",
    minWidth: 140,
    align: "right",
  },
  {
    id: "platform",
    label: "Platform",
    minWidth: 100,
    align: "right",
  },
  {
    id: "version",
    label: "Version",
    minWidth: 100,
    align: "right",
  },
  {
    id: "Url",
    label: "Store URL",
    minWidth: 100,
    align: "right",
  },
  {
    id: "delete",
    label: "",
    minWidth: 50,
    align: "right",
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

export default function StickyHeadTable({
  open,
  searchValue,
  selectedCategoryIds,
  selectedPlatform,
  appAdded,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [apps, setApps] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [count, setCount] = useState(0);
  const [deletedRow, setDeletedRow] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [appUpdated, setAppUpdated] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (row) => {
    setOpenDeleteModal(true);
    setDeletedRow(row);
  };

  const handleRowClick = (row) => {
    setOpenDrawer(true);
    setSelectedRow(row);
  };

  useEffect(() => {
    getApps(searchValue);
  }, [
    searchValue,
    selectedCategoryIds,
    selectedPlatform,
    page,
    rowsPerPage,
    isDeleted,
    appAdded,
    appUpdated,
  ]);

  function getApps(searchValue) {
    request("/admin/app/list", "POST", {
      page: page + 1,
      pageCount: rowsPerPage,
      searchValue,
      categories: selectedCategoryIds,
      platform: selectedPlatform,
    }).then((res) => {
      if (res.success) {
        setCount(parseInt(res.count));
        setApps(res.data);
      }
    });
  }

  if (open) {
    return (
      <>
        {openDrawer ? (
          <DetailsSideBar
            anchor={"right"}
            selectedRow={selectedRow}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            appUpdated={appUpdated}
            setAppUpdated={setAppUpdated}
          />
        ) : null}
        <div style={{ marginLeft: "20px", marginTop: "20px" }}>
          <Paper sx={{ width: "99%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "calc(100vh - 260px)" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow hover={true}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {apps.map((row) => {
                    return (
                      <TableRow
                        style={{ cursor: "pointer" }}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`${row.id}${row.platform}`}
                        onClick={() => handleRowClick(row)}
                      >
                        <TableCell align={"left"}>{row.order}</TableCell>
                        <TableCell key={"name"} align={"left"}>
                          {row.name}
                        </TableCell>
                        <TableCell key={"icon"} align={"right"}>
                          <img height={30} src={row["icon"]}></img>
                        </TableCell>
                        <TableCell key={"title"} align={"right"}>
                          {row.title}
                        </TableCell>
                        <TableCell key={"categories"} align={"right"}>
                          {row.categories}
                        </TableCell>

                        <TableCell key={"showInIntroScreen"} align="center">
                          <Checkbox
                            sx={{ p: 0 }}
                            disabled
                            checked={row.showInIntroScreen}
                          />
                        </TableCell>
                        <TableCell key={"showInMainScreen"} align="center">
                          <Checkbox
                            sx={{ p: 0 }}
                            disabled
                            checked={row.showInMainScreen}
                          />
                        </TableCell>
                        <TableCell key={"platform"} align={"right"}>
                          {row.platform}
                        </TableCell>
                        <TableCell key={"version"} align={"right"}>
                          {row.version}
                        </TableCell>
                        <TableCell
                          key={"url"}
                          align={"right"}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {row["url"] ? (
                            <a href={row["url"]} target="_blank">
                              Go to Store
                            </a>
                          ) : null}
                        </TableCell>
                        <TableCell key={"delete"} align={"right"}>
                          <DeleteForeverOutlinedIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(row);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 30, 50]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <DeleteApp
            deletedRow={deletedRow}
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            apps={apps}
            setApps={setApps}
            isDeleted={isDeleted}
            setIsDeleted={setIsDeleted}
          />
        </div>
      </>
    );
  } else return null;
}
