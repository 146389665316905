import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import request from "../functions/request";
import Delete from "../Delete";

const columns = [
  { id: "order", label: "Order", minWidth: 100 },
  { id: "name", label: "Անուն", minWidth: 170 },
  { id: "nameEn", label: "Name", minWidth: 170 },
  { id: "nameRu", label: "Имя", minWidth: 170 },
  { id: "delete", label: "", minWidth: 50 },
];

export default function CategoriesTable({ open, searchValue, added, onEdit }) {
  const [categories, setCategories] = React.useState([]);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deletedCategoryId, setDeletedCategoryId] = React.useState(0);

  useEffect(() => {
    getCategories();
  }, [searchValue, added]);

  function getCategories() {
    request("/admin/category/list", "POST", {
      page: null,
      count: null,
      searchValue,
    }).then((res) => {
      if (res.success) {
        setCategories(res.data);
      }
    });
  }

  const handleDelete = (id) => {
    setOpenDeleteModal(true);
    setDeletedCategoryId(id);
  };

  if (open) {
    return (
      <div style={{ marginLeft: "20px", marginTop: "20px" }}>
        <Paper sx={{ width: "99%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "calc(100vh - 240px)" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell align="left">{row.order}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.nameEn}</TableCell>
                      <TableCell align="left">{row.nameRu}</TableCell>
                      <TableCell align="right">
                        <div>
                          <ModeEditOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => onEdit(row)}
                          />
                          <DeleteForeverOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleDelete(row.id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Delete
          categoryId={deletedCategoryId}
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          categories={categories}
          setCategories={setCategories}
        />
      </div>
    );
  } else return null;
}
