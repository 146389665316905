import React, { useState, useEffect, } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import request from "../functions/request"
import { toast } from 'react-toastify';

export default function DeleteApp({ deletedRow, openDeleteModal, setOpenDeleteModal, apps, setApps, isDeleted, setIsDeleted }) {

  async function deleteApp() {
    await request("/admin/app/delete", "POST", { storeId: deletedRow.storeId, platform: deletedRow.platform }).then(res => {
      if (res.success) {
        setIsDeleted(!isDeleted)
      }
    })
    toast("App is deleted")

  }

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setOpenDeleteModal(false);
    deleteApp()
  };

  return (
    <div>
      <Dialog
        open={openDeleteModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete App "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
