export const HOST = "https://admin.armapps.am";
export async function requestFormData(
  url,
  method = "GET",
  data = null,
  headers = {}
) {
  headers["token"] = localStorage.getItem("token");

  url = HOST + url;

  let result = await fetch(url, {
    method: method,
    headers: headers,
    body: data,
  });
  if (url.includes("login") && result.headers.get("token"))
    localStorage.setItem("token", result.headers.get("token"));

  if (result.status === 401) {
    localStorage.removeItem("token");
    window.location.reload();
    window.location.replace("/signIn");
  }
  result = await result.json();
  return result;
}
async function request(url, method = "GET", data = null, headers = {}) {
  if (data) {
    method = "POST";
    data = JSON.stringify(data);
    headers["Content-Type"] = "application/json";
  }

  headers["token"] = localStorage.getItem("token");

  url = HOST + url;

  let result = await fetch(url, {
    method: method,
    headers: headers,
    body: data,
  });
  if (url.includes("login") && result.headers.get("token"))
    localStorage.setItem("token", result.headers.get("token"));

  if (result.status === 401) {
    localStorage.removeItem("token");
    window.location.reload();
    window.location.replace("/signIn");
  }
  result = await result.json();
  return result;
}

export default request;
