import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { HOST } from "../../functions/request";
import SelectCategories from "../../SelectCategories";

export function AddOrEditBanner({ open, onClose, onSubmit, banner }) {
  const [name, setName] = useState(banner ? banner.name : "");
  const [image, setImage] = useState(banner ? `${HOST}${banner.image}` : "");
  const [link, setLink] = useState(banner ? banner.link : "");
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(
    banner ? banner.categories : []
  );
  const [showInMobile, setShowInMobile] = useState(
    banner ? banner.showInMobile : false
  );
  const [imageFile, setImageFile] = useState("");

  const uploadImageRef = useRef();

  const isValid = useMemo(() => {
    return !!(name && image && link && selectedCategoryIds.length > 0);
  }, [name, image, link]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault(selectedCategoryIds);
      if (isValid) {
        onSubmit({
          id: banner?.id,
          name,
          image: imageFile,
          link,
          categories: selectedCategoryIds,
          showInMobile,
        });
      } else {
        return false;
      }
    },
    [banner, name, image, link, showInMobile, selectedCategoryIds]
  );

  const uploadImage = useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
      let reader = new FileReader();
      reader.onloadend = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{banner ? "Edit Banner" : "Add New Banner"}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Banner Name"
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            value={name}
            required
            my={1}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="link"
            label="Banner Link"
            type="text"
            required
            fullWidth
            variant="standard"
            autoComplete="off"
            value={link}
            my={1}
            onChange={(e) => setLink(e.target.value)}
          />
          <SelectCategories
            selectedCategoryIds={selectedCategoryIds}
            setSelectedCategoryIds={setSelectedCategoryIds}
          />

          <FormGroup
            sx={{
              my: 1,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={showInMobile}
                  onChange={(e) => setShowInMobile(!showInMobile)}
                />
              }
              label="Show in main mobile"
            />
          </FormGroup>
          <Box display="flex" flexDirection="column">
            {!!image && <img src={image} width={320} height={180} />}

            <Button
              variant="contained"
              onClick={() => uploadImageRef.current.click()}
              id="image"
              sx={{ my: 1 }}
            >
              {!image ? "Upload Banner Image" : "Replace Banner Image"}
              <input
                hidden
                required
                ref={uploadImageRef}
                type="file"
                accept="image/*"
                onChange={uploadImage}
              />
            </Button>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" onClick={handleSubmit} disabled={!isValid}>
          {banner ? "Edit" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
