import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import React, { useCallback, useEffect, useState } from "react";
import request, { HOST, requestFormData } from "../../functions/request";
import { AddOrEditBanner, DeleteDialog } from "../../components";
import SelectCategories from "../../SelectCategories";

export function Banners() {
  const [banners, setBanners] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addOrEditBannerOpen, setAddOrEditBannerOpen] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState();
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);

  const handleOpenEdit = useCallback((banner) => {
    setSelectedBanner(banner);
    setAddOrEditBannerOpen(true);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getBanners();
  }, [selectedCategoryIds]);

  const openDeleteModal = useCallback((banner) => {
    setSelectedBanner(banner);
    setDeleteModalOpen(true);
  }, []);

  const openAddBannerModal = useCallback((banner) => {
    setSelectedBanner(null);
    setAddOrEditBannerOpen(true);
  }, []);
  const deleteBanner = useCallback(() => {
    setDeleteModalOpen(false);
    request(`/admin/banners/${selectedBanner.id}`, "DELETE").then((res) => {
      if (res.success) {
        getBanners();
      }
    });
  }, [selectedBanner]);

  const addOrEditBanner = (banner) => {
    const formData = new FormData();
    formData.append("name", banner.name);
    formData.append("id", banner.id);
    formData.append("link", banner.link);
    formData.append("image", banner.image);
    formData.append("showInMobile", banner.showInMobile);
    formData.append("categories", banner.categories);
    setAddOrEditBannerOpen(false);

    if (banner.id) {
      requestFormData(`/admin/banners`, "PUT", formData).then((res) => {
        if (res.success) {
          getBanners();
        }
      });
    } else {
      requestFormData(`/admin/banners/add`, "POST", formData).then((res) => {
        if (res.success) {
          getBanners();
        }
      });
    }
  };

  function getBanners() {
    request("/admin/banners", "POST", {
      page: page,
      pageCount: rowsPerPage,
      categories: selectedCategoryIds,
    }).then((res) => {
      if (res.success) {
        setCount(parseInt(res.count));
        setBanners(res.data);
      }
    });
  }
  return (
    <main>
      <Box m={2}>
        <SelectCategories setSelectedCategoryIds={setSelectedCategoryIds} />
        <Button onClick={openAddBannerModal} size="large" variant="contained">
          Add Banner
        </Button>
      </Box>
      {addOrEditBannerOpen && (
        <AddOrEditBanner
          open={addOrEditBannerOpen}
          onClose={() => setAddOrEditBannerOpen(false)}
          banner={selectedBanner}
          onSubmit={addOrEditBanner}
        />
      )}
      <DeleteDialog
        title="Delete Banner"
        open={deleteModalOpen}
        onSubmit={deleteBanner}
        onClose={() => setDeleteModalOpen(false)}
      />
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow hover={true}>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Show In Mobile</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {banners.map((row, i) => {
              return (
                <TableRow
                  style={{ cursor: "pointer" }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  onClick={() => handleOpenEdit(row)}
                >
                  <TableCell align={"left"}>
                    <img
                      height={30}
                      width={60}
                      src={`${HOST}${row.image}`}
                    ></img>
                  </TableCell>
                  <TableCell align={"left"}>{row.name}</TableCell>
                  <TableCell align="left">
                    <Checkbox
                      sx={{ p: 0 }}
                      disabled
                      checked={row.showInMobile}
                    />
                  </TableCell>

                  <TableCell align={"right"}>
                    <DeleteForeverOutlinedIcon
                      onClick={(e) => {
                        openDeleteModal(row);
                        e.stopPropagation();
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </main>
  );
}
